html,
body {
  margin: 0;
  padding: 0;
  background-color: rgb(240, 115, 201);
  font-family: "Lucida Sans", sans-serif;
}

label {
  display: block;
}

#wrapper {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}
